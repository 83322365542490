import { List, message, Typography } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkCameraSupport } from "../../utilities";
import interviewActions from "../../redux/actions/interview.actions";

export const InterviewGettingStartedPage = ({ interview }) => {
  const dispatch = useDispatch();

  const handleRecordingTry = () => {
    checkCameraSupport()
      .then(() => {
        interviewActions.createCandidate(null, true)(dispatch);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const data = [
    "Ensure you are in a quiet, distraction-free area.",
    "Check you have enough charge on your device and a good data connection.",
    "Practice to feel comfortable with the process.",
    "Make eye contact with the camera whenever possible.",
    "You will have the opportunity to review all your answers at the end of the process before submitting them.",
    "Remember to grant access to your camera and microphone if requested.",
  ];
  return (
    <div className="p-4 bg-white rounded-md w-full mx-auto">
      {interview?.uuid ? (
        <div className="space-y-4">
          {/*<Typography.Title level={2} className='font-medium text-center' >*/}
          {/*    How to record a great video interview*/}
          {/*</Typography.Title>*/}
          {/*<div className="p-2">*/}
          {/*    <div className='aspect-video'>*/}
          {/*        <iframe*/}
          {/*            width='100%'*/}
          {/*            height='100%'*/}
          {/*            src={`https://www.youtube.com/embed/QTJ2Jjzr7u4?autoplay=0&mute=0&controls=0&origin=https%3A%2F%2Fapp.willotalent.com&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1`}*/}
          {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
          {/*            allowFullScreen*/}
          {/*            title="Embedded youtube"*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div>
            <Typography.Title
              level={1}
              className="mx-auto text-center md:text-start font-bold text-2xl"
            >
              Top tips for recording your video responses
            </Typography.Title>
            <List
              size="small"
              className="list-disc"
              dataSource={data}
              split={false}
              renderItem={(item, index) => (
                <List.Item
                  className="text-lg -my-1.5 lg:-mr-32"
                >
                  <i
                    className="fa-solid fa-circle mr-2"
                    style={{ fontSize: "6px " }}
                  />
                  {item}
                </List.Item>
              )}
            />
          </div>
          <div className="flex flex-col self-center items-center gap-2">
            <button
              type="button"
              className="py-2 px-5 mt-6 border-2 border-primary-800 rounded-full text-xl cursor-pointer text-primary-800"
              onClick={handleRecordingTry}
            >
              Try it out
            </button>
            <small className="text-sm font-semibold text-gray-500">
              No pressure
            </small>
          </div>
        </div>
      ) : (
        <div>
          <p>No interview to show</p>
        </div>
      )}
      <ShareComponent />
    </div>
  );
};

export const ShareComponent = () => {
  // useEffect(()=> {
  //     window.addEventListener('beforeunload', function (e) {
  //         e.preventDefault();
  //         e.returnValue = '';
  //     });
  // },[])
  const [isOpen, setIsOpen] = React.useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success("Copied to clipboard");
  };
  return null;
  // return (<>
  // <div className='fixed top-50 right-0 p-2 rounded-l-md shadow-md bg-white border-2 border-algaeGreen-500 -translate-y-2/4'>
  //     {
  //         isOpen ?
  //             (<div className=' flex items-center space-x-2'>
  //                 <div onClick={() => setIsOpen(false)}>
  //                     <RightOutlined/>
  //                 </div>
  //                 <div className='w-20'>
  //                     <p>Use this link to transfer to another device</p>
  //                     <span className='text-sm text-algaeGreen-500'
  //                           onClick={handleCopy}><CopyOutlined/>  copy</span>
  //                 </div>
  //             </div>) :
  //             (<div onClick={() => setIsOpen(true)}>
  //                 <LeftOutlined/>
  //             </div>)
  //     }
  //
  // </div>)
};
